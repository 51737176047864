'use client'
import Script from 'next/script'
import { memo } from 'react'
import { areTaggingAdsDisabled } from '../head-script'

export const ThirdPartyTags = memo(() => {
  const noTags = areTaggingAdsDisabled()
  if (noTags) {
    return null
  }
  return (
    <>
      <Script
        id='script-gpt'
        type='text/javascript'
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        strategy='afterInteractive'
        onReady={() => {
          try {
            window.googletag = window.googletag || { cmd: [] }
            window.googletag.cmd.push(function () {
              window.googletag.pubads().addEventListener('slotOnload', function (event) {
                if (!window.adRendered) {
                  window.adRendered = []
                }
                var slotId = event.slot.getSlotElementId()
                if (window.adRendered.indexOf(slotId) !== -1) {
                  return
                }

                var responseInformation = event.slot.getResponseInformation()
                // Record details of the rendered ad.
                var details = {
                  LineItemID: responseInformation.lineItemId,
                  AdvertiserID: responseInformation.advertiserId,
                  CampaignID: responseInformation.campaignId,
                  CreativeID: responseInformation.creativeId,
                  SourceAgnosticCreativeID: responseInformation.sourceAgnosticCreativeId,
                  SourceAgnosticLineItemID: responseInformation.sourceAgnosticLineItemId
                }
                window.adRendered.push(slotId)
                window.dataLayer.push({
                  event: 'GPTSlotRendered',
                  GPTSlotId: slotId,
                  GPTDetails: details
                })
              })
            })
          } catch (e) {
            //eslint-disable-next-line
            console.log('GPTSlotEventError', e)
          }
        }}
      />
      <Script
        id='script-ttd'
        strategy='lazyOnload'
        src='https://js.adsrvr.org/up_loader.1.1.0.js'
        onLoad={() => {
          var TTDFlag = 0
          if (!TTDFlag) {
            try {
              window.ttd_dom_ready(function () {
                if (typeof TTDUniversalPixelApi === 'function') {
                  var universalPixelApi = new window.TTDUniversalPixelApi()
                  universalPixelApi.init(
                    'fp9djii',
                    ['wtzivnd'],
                    'https://insight.adsrvr.org/track/up'
                  )
                  TTDFlag = 1
                }
              })
            } catch (e) {
              //eslint-disable-next-line
              console.log('TTD Error', e)
              TTDFlag = 1
            }
          }
        }}
      />
      <Script
        id='script-brandmetrics'
        strategy='lazyOnload'
        type='text/javascript'
        src='https://cdn.brandmetrics.com/tag/6d50a59e0678491a928b99c84ad66b6f/drive.js'
      />
    </>
  )
})
