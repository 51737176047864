'use client'
import React, { useContext, useLayoutEffect } from 'react'
import { PageContext } from '@grille/utils/contexts/pageContext'
import { IPageContext } from '@grille/types/core'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
import { DriveCache } from '@grille/utils/class/DriveCache'
import { GEO_CACHE_KEY, GEO_CACHE_PERSIST_SECONDS } from '@grille/constants/localStorage'
import isEmpty from 'lodash/isEmpty'
import logger from '@grille/utils/logger'

export function GeoLocationWrapper({ children }: GeoLocationWrapperProps) {
  const { pageStore } = useContext<IPageContext>(PageContext)
  const { pagePubSub } = pageStore || {}
  /* Fetch country code for the app to consume
   * used by liveChat and geo location deps component such as marketplace and CFS blocks component
   * in oder to only display content based on selected country or states
   */
  useLayoutEffect(() => {
    if (typeof window === 'undefined') return

    const NEXT_PUBLIC_GEO_CITY_API_URL =
      getRuntimeEnv('NEXT_PUBLIC_GEO_CITY_API_URL') ||
      'https://dev-api.drivemustang.com.au/geo/city'

    const storage = new DriveCache(
      {},
      { persist: true, cacheKey: GEO_CACHE_KEY, persistTimeInSec: GEO_CACHE_PERSIST_SECONDS }
    )

    const storeAndNotify = (geoData: any) => {
      pagePubSub?.publish('geoLocationDetected', geoData)
    }

    const setGeoLocationData = async () => {
      try {
        const response = await fetch(NEXT_PUBLIC_GEO_CITY_API_URL as string, { method: 'get' })

        const { data } = (await response.json()) || {}
        if (!isEmpty(data)) {
          storage.set(GEO_CACHE_KEY, data)
          storeAndNotify(data)
        } else {
          storage.set('error', true) // sets error
          storeAndNotify(undefined)
          logger.error(
            `[GeoLocation error] - Geo-location-wrapper.js [message: geo location data is empty]`
          )
        }
      } catch (error) {
        storage.set('error', true) // sets error
        storeAndNotify(undefined)
        logger.error(`[GeoLocation error] - Geo-location-wrapper.js ${JSON.stringify(error)}]`)
      }
    }

    const geoData = storage.get(GEO_CACHE_KEY)
    const hasError = storage.get('error')
    if (!isEmpty(geoData) && !hasError) {
      storeAndNotify(geoData)
    } else {
      storage.reset() // remove any error if there was any
      setGeoLocationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // only once
  return <>{children}</>
}

type GeoLocationWrapperProps = {
  children: React.ReactNode
}
