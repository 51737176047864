'use client'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
const deploymentType = getRuntimeEnv('NEXT_PUBLIC_DEPLOY_ENV')
const GTMNoScript = () => {
  let src = null
  if (deploymentType === 'dev') {
    src =
      'https://www.googletagmanager.com/ns.html?id=GTM-NXB4GPT&gtm_auth=_Yk_ZK41H-dTVZvsG1rFhg&gtm_preview=env-3&gtm_cookies_win=x'
  }

  if (deploymentType === 'staging') {
    src =
      'https://www.googletagmanager.com/ns.html?id=GTM-NXB4GPT&gtm_auth=ma2HrRYocTLAFr2fV5bB3g&gtm_preview=env-4&gtm_cookies_win=x'
  }

  if (deploymentType === 'prod') {
    src =
      'https://www.googletagmanager.com/ns.html?id=GTM-NXB4GPT&gtm_auth=CDQi8afk4naL6DOdxkEk4A&gtm_preview=env-1&gtm_cookies_win=x'
  }
  return (
    <noscript>
      <iframe src={src} height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} />
    </noscript>
  )
}

export default GTMNoScript
