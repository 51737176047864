'use client'
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { FunctionComponent } from 'react'
import Script from 'next/script'
import { IAS_PUB_ID } from '@grille/constants/ads'

export const HeadScripts: FunctionComponent<HeadScriptsProps> = ({ includeAdX = true }) => {
  return (
    <>
      {includeAdX && (
        <Script
          id='script-ias'
          type='text/javascript'
          src='https://static.adsafeprotected.com/iasADX.js'
          async
          strategy='beforeInteractive'
          onLoad={() => window.__iasADX.start(IAS_PUB_ID)}
        />
      )}
      <Script
        id='script-ias-pet'
        type='text/javascript'
        src='https://static.adsafeprotected.com/iasPET.1.js'
        async
        strategy='afterInteractive'
      />
    </>
  )
}

export type HeadScriptsProps = {
  includeAdX?: boolean
}
