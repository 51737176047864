'use client'
import React, { useMemo } from 'react'

import { ApolloProvider, createQueryPreloader, PreloadQueryFunction } from '@apollo/client'
import { getClient } from '@grille/lib/client'
import { PageProvider } from '@grille/utils/contexts/pageContext'
import PubSub, { PubSubStatic } from '@grille/utils/class/PubSub'
import { GeoLocationWrapper } from './geo-location-wrapper'

/* declare once per application
 * recreate for different page since we have opted out SPA
 */
const PRELOAD_QUERY: PreloadQueryFunction = createQueryPreloader(getClient())
export default PRELOAD_QUERY

export function ApolloWrapper({ children }: ApolloWrapperProps) {
  const pagePubSub: PubSubStatic = useMemo(
    () => new PubSub({ allowMultiSubscribers: true }).export(),
    []
  )
  const initialPageStore = {
    hideRoofrackAd: false,
    pagePubSub
  }

  return (
    <ApolloProvider client={getClient()}>
      <PageProvider initialPageStore={initialPageStore}>
        <GeoLocationWrapper>{children}</GeoLocationWrapper>
      </PageProvider>
    </ApolloProvider>
  )
}

type ApolloWrapperProps = React.PropsWithChildren
