import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
import isEmpty from 'lodash/isEmpty'

/**
 * Function to generate adfixus script uri using env values
 * @returns {string} Adfixcus script url
 */
export const generateUrl = () => {
  const frontendUri = getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')
  const adFixusUrl = getRuntimeEnv('NEXT_PUBLIC_ADFIXUS_PATH')
  if (isEmpty(frontendUri) || isEmpty(adFixusUrl)) {
    return null
  }
  return `${frontendUri}${adFixusUrl}`
}

/**
 * Function to check if adfixus is enabled
 * @returns {boolean} Returns true if adfixus is enabled
 */

export const isAdFixusEnabled = () => {
  return Boolean(Number(getRuntimeEnv('NEXT_PUBLIC_ADFIXUS_ENABLED')))
}
