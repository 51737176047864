'use client'
import Script from 'next/script'
import { FunctionComponent } from 'react'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

import vwoScript from '@grille/components/common/vwo-scripts/smart-code'
import AdfixusScript from '../adfixus'

export const ThirdPartyScripts: FunctionComponent = () => {
  return (
    <>
      <Script id='vwoCode' strategy='afterInteractive' async>
        {vwoScript((getRuntimeEnv('NEXT_PUBLIC_DEPLOY_ENV') as string) || 'dev')}
      </Script>
      <AdfixusScript />
    </>
  )
}
