import {
  split,
  DefaultOptions,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import tailpipeApolloLinks from '../apollo/tailpipe-link'
import wordpressApolloLinks from '../apollo/wordpress-link'
import listingsApolloLinks from '../apollo/listings-link'

import { retryLink } from '../apollo/retry-link'

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  }
}

export const defaultOptionsSSR: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  }
}

/**
 * Switches between Tailpipe, WordPress or Listings Endpoints.
 */
export const link = split(
  (operation) => {
    return operation.getContext()?.tailpipe
  },
  tailpipeApolloLinks,
  split(
    (operation) => {
      return operation.getContext()?.listing
    },
    listingsApolloLinks,
    wordpressApolloLinks
  )
)

export const getClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: retryLink.concat(link),
    defaultOptions: typeof window === 'undefined' ? defaultOptionsSSR : defaultOptions
  })
}
