import Script from 'next/script'
import { generateUrl, isAdFixusEnabled } from './helper'

/**
 * Component to render Adfixus script
 */
const AdfixusScript = () => {
  const url = generateUrl()

  // Only render if Adfixus is enabled and we have valid url
  if (!isAdFixusEnabled() || !url) {
    return null
  }
  return (
    <>
      <Script id='adfixus'>var afx_start = Date.now();</Script>
      <Script src={url} strategy='beforeInteractive'></Script>
    </>
  )
}

export default AdfixusScript
